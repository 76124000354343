import { getTraceId } from '@utils/trace-utils';
import axios from 'axios';
import { getConfig } from 'bernie-config';
import { serializeError } from 'serialize-error';
import { ERROR, JSON_HEADERS, TRACE_ID } from 'src/constants';

const SERVICE_NAME = 'PARTNER_PRODUCT_SERVICE';

export interface IProduct {
  id: string;
  name: string;
  description: string;
  type: string;
}

export const getPartnerIsSubscribed: (request, principalToken, partnerAccountId) => Promise<boolean> = async (
  request,
  principalToken,
  partnerAccountId,
) => {
  const traceId = getTraceId(request);
  request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getPartnerIsSubscribed', traceId], {
    message: `Checking if partner account ${partnerAccountId} is subscribed to sponsored-content-campaign-management`,
  });

  if (!principalToken || !partnerAccountId) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.getPartnerIsSubscribed', traceId], {
      message: 'No partner account id or token available',
    });
    throw new Error('Unauthorized');
  }

  const {
    services: { partnerProductService },
  } = getConfig();

  const { protocol, hostname } = partnerProductService;

  try {
    const response = await axios({
      method: 'GET',
      url: `${protocol}//${hostname}/v2/products/sponsored-content-campaign-management/selections?partnerAccountIds=${partnerAccountId}`,
      signal: AbortSignal.timeout(5000),
      headers: {
        ...JSON_HEADERS,
        [TRACE_ID]: traceId,
        Authorization: `EGToken Principal-JWT=${principalToken.encodedJwt}`,
      },
    });

    request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getPartnerIsSubscribed', traceId], {
      message: `Partner account subscription check for partner account ${partnerAccountId} complete`,
      data: response.data,
    });

    const subscription = response?.data.items.find((sub) => sub.partner_account_id === partnerAccountId);
    return subscription && subscription['product_status'] === 'SELECTED';
  } catch (error) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.getPartnerIsSubscribed', traceId], {
      error: serializeError(error),
    });
    return null;
  }
};

/**
 * Use this call when checking using a partners token
 */
export const getPartnerIsSubscribedPartnerCall: (
  request,
  principalToken,
  partnerAccountId,
) => Promise<boolean> = async (request, principalToken, partnerAccountId) => {
  const traceId = getTraceId(request);
  request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getPartnerIsSubscribed', traceId], {
    message: `Checking if partner account ${partnerAccountId} is subscribed to sponsored-content-campaign-management`,
  });

  if (!principalToken || !partnerAccountId) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.getPartnerIsSubscribed', traceId], {
      message: 'No partner account id or token available',
    });
    throw new Error('Unauthorized');
  }

  const {
    services: { partnerProductService },
  } = getConfig();

  const { protocol, hostname } = partnerProductService;

  try {
    const response = await axios({
      method: 'GET',
      url: `${protocol}//${hostname}/v1/accounts/${partnerAccountId}/products/sponsored-content-campaign-management`,
      signal: AbortSignal.timeout(5000),
      headers: {
        ...JSON_HEADERS,
        [TRACE_ID]: traceId,
        Authorization: `EGToken Principal-JWT=${principalToken.encodedJwt}`,
      },
    });

    request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getPartnerIsSubscribed', traceId], {
      message: `Partner account subscription check for partner account ${partnerAccountId} complete`,
      data: response.data,
    });

    return response.data || {};
  } catch (error) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.getPartnerIsSubscribed', traceId], {
      error: serializeError(error),
    });
    return null;
  }
};
