import { getApiClientPrincipalToken, getJwtFromToken, IPrincipalResponse } from '@services/identity-token-service';
import { PARTNER_ROLES } from '@utils/controller-utils';
import { getTraceId } from '@utils/trace-utils';
import axios from 'axios';
import { getConfig } from 'bernie-config';
import { serializeError } from 'serialize-error';
import { ERROR, TRACE_ID } from 'src/constants';

import { getPartnerIsSubscribed } from './partner-product-service';

const SERVICE_NAME = 'EXCALIBUR_SERVICE';

export interface IPartnerAccountRoles {
  userId: string;
  partnerAccountId: string;
  roles: IPartnerAccountRole[];
}

export interface IPartnerAccountRole {
  id: string;
  name: string;
  description: string;
  product: IProduct;
}

export interface IProduct {
  id: string;
  name: string;
}

export const isUserSponsoredContentUser: (request, token: IPrincipalResponse) => Promise<boolean> = async (
  request,
  token,
) => {
  const traceId = getTraceId(request);
  const {
    services: { excaliburService },
    app: { sponsoredContentPartnerAccountId },
  } = getConfig();

  if (!token) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.isUserSponsoredContentUser', traceId], {
      message: 'no principal token',
    });
    throw new Error('Unauthorized');
  }

  const principalJwt = getJwtFromToken(token.encodedJwt);
  const userSubId = principalJwt.sub;

  request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.isUserSponsoredContentUser', traceId], {
    message: `checking user ${userSubId} is sponsoredContent internal user`,
  });

  const sponsoredContentResponse = await axios({
    method: 'GET',
    url: `${excaliburService.protocol}//${excaliburService.hostname}/roles/${sponsoredContentPartnerAccountId}/${userSubId}`,
    headers: {
      Authorization: `EGToken Principal-JWT=${token.encodedJwt}`,
      clientId: 'advertiser-portal-pwa',
      [TRACE_ID]: traceId,
    },
  });

  let scUser = false;

  if (sponsoredContentResponse.status == 200) {
    const sponsoredContentRoles = sponsoredContentResponse.data.roles;
    if (sponsoredContentRoles.length > 0) {
      scUser = true;
    }
  }

  request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.isUserSponsoredContentUser', traceId], {
    message: `user ${userSubId} is sponsoredContent internal user result = ${scUser}`,
  });

  return scUser;
};

export const getRolesInPartnerAccount: (
  request,
  token: IPrincipalResponse,
  partnerAccountId: string,
  validateExisting?: boolean,
) => Promise<IPartnerAccountRole[]> = async (request, token, partnerAccountId, validateExisting = false) => {
  const traceId = getTraceId(request);

  if (!token) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.getRolesInPartnerAccount', traceId], {
      message: 'no principal token',
    });
    throw new Error('Unauthorized');
  }

  const principalJwt = getJwtFromToken(token.encodedJwt);
  const userSubId = principalJwt.sub;
  request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getRolesInPartnerAccount', traceId], {
    message: `getting roles for user ${userSubId} and partnerAccount ${partnerAccountId}`,
  });

  const {
    services: { excaliburService },
    app: { sponsoredContentPartnerAccountId },
    catalyst: {
      server: {
        app: { emulationClient },
      },
    },
  } = getConfig();

  try {
    const rolesResponse = await axios({
      method: 'GET',
      url: `${excaliburService.protocol}//${excaliburService.hostname}/roles/${partnerAccountId}/${userSubId}`,
      headers: {
        [TRACE_ID]: traceId,
        Authorization: `EGToken Principal-JWT=${token.encodedJwt}`,
        clientId: 'advertiser-portal-pwa',
      },
      signal: AbortSignal.timeout(5000),
    });

    if (rolesResponse.status != 200) {
      const msg = rolesResponse.data;
      request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.getRolesInPartnerAccount', traceId], {
        message: `getting roles for user ${userSubId} and partnerAccount ${partnerAccountId}`,
        error: serializeError(msg),
      });
      throw new Error('Problem getting role data'); //todo
    }

    let roles = rolesResponse.data.roles;

    // THIS IS TEMPORARY CODE UNTIL WE HAVE A BETTER WAY TO VALIDATE CORRECT ROLE USAGE ON INVITE
    if (validateExisting) {
      const neededRoles = [];

      const scRoles = roles.filter((role) => role.product.id == 'sponsored-content-campaign-management');
      const managedRoles = roles.filter((role) => role.product.id != 'sponsored-content-campaign-management');

      request.log([SERVICE_NAME, 'advertiser-portal-pwa.debug.getRolesInPartnerAccount', traceId], {
        message: `validating roles for user ${userSubId} and partnerAccount ${partnerAccountId}`,
        data: `scRoles=${JSON.stringify(scRoles)}, managedRoles=${JSON.stringify(managedRoles)}`,
      });

      // need at least 1 SC role
      if (scRoles.length == 0) {
        if (managedRoles.length > 0) {
          if (managedRoles.some((role) => role.id == 'administrator')) {
            neededRoles.push(PARTNER_ROLES.ADVERTISER_ADMIN.toString());
          }
          if (managedRoles.some((role) => role.id == 'viewer')) {
            neededRoles.push(PARTNER_ROLES.ADMIN.toString()); // FSL users (name to be FSL Admin)
          }
        }
      }

      // need at least 1 partner role
      if (managedRoles.length == 0) {
        if (scRoles.length > 0) {
          if (scRoles.some((role) => role.id == PARTNER_ROLES.ADVERTISER_ADMIN)) {
            neededRoles.push('administrator');
          } else {
            neededRoles.push('viewer');
          }
        }
      }

      if (partnerAccountId != sponsoredContentPartnerAccountId && neededRoles.length > 0) {
        const apiClientToken = await getApiClientPrincipalToken(
          request,
          emulationClient.clientId,
          emulationClient.clientSecret,
        );
        const isSubscribed = await getPartnerIsSubscribed(request, apiClientToken, partnerAccountId);

        if (isSubscribed) {
          request.log([SERVICE_NAME, 'advertiser-portal-pwa.debug.getRolesInPartnerAccount', traceId], {
            message: `adding needed roles ${neededRoles} for user ${userSubId} and partnerAccount ${partnerAccountId}`,
          });
          await setRoleInPartnerAccount(request, apiClientToken, userSubId, partnerAccountId, neededRoles);
          const updatedRolesResponse = await axios({
            method: 'GET',
            url: `${excaliburService.protocol}//${excaliburService.hostname}/roles/${partnerAccountId}/${userSubId}`,
            headers: {
              [TRACE_ID]: traceId,
              Authorization: `EGToken Principal-JWT=${token.encodedJwt}`,
              clientId: 'advertiser-portal-pwa',
            },
            signal: AbortSignal.timeout(5000),
          });
          roles = updatedRolesResponse.data.roles;
        }
      }
    }
    // END

    request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getRolesInPartnerAccount', traceId], {
      message: `roles before pruning for user ${userSubId} and partnerAccount ${partnerAccountId}`,
      data: roles,
    });

    // if external user, remove the non product roles
    if (principalJwt.partner_account_id !== sponsoredContentPartnerAccountId) {
      roles = roles.filter((role) => role.product.id == 'sponsored-content-campaign-management');
    }

    request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getRolesInPartnerAccount', traceId], {
      message: `product roles for user ${userSubId} and partnerAccount ${partnerAccountId}`,
      data: roles,
    });

    return roles;
  } catch (error) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.getRolesInPartnerAccount', traceId], {
      message: `product roles for user ${userSubId} and partnerAccount ${partnerAccountId}`,
      error: serializeError(error),
    });
    throw error;
  }
};

export const setRoleInPartnerAccount = async (
  request,
  token,
  userId,
  partnerAccountId,
  roles: string[],
  ttl: number = null,
) => {
  const traceId = getTraceId(request);
  const {
    services: { excaliburService },
  } = getConfig();

  if (!token) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.setRoleInPartnerAccount', traceId], {
      message: 'no principal token',
    });
    throw new Error('Unauthorized');
  }

  try {
    request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.setRoleInPartnerAccount', traceId], {
      message: `adding role(s) ${roles} to Partner Account ${partnerAccountId} for user ${userId}`,
      ttl: ttl,
    });

    const body = {
      rolesToAdd: [...roles],
      rolesToRemove: [],
    };

    if (ttl) {
      body['ttlSeconds'] = ttl;
    }

    const response = await axios({
      method: 'PUT',
      url: `${excaliburService.protocol}//${excaliburService.hostname}/roles/${partnerAccountId}/${userId}`,
      headers: {
        [TRACE_ID]: traceId,
        Authorization: `EGToken Principal-JWT=${token.encodedJwt}`,
        clientId: 'advertiser-portal-pwa',
      },
      signal: AbortSignal.timeout(5000),
      data: body,
    });

    if (response.status == 200) {
      request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.setRoleInPartnerAccount', traceId], {
        message: `Product role ${roles} added for user=${userId} for partnerAccount=${partnerAccountId}, ${JSON.stringify(
          response.data,
        )}`,
        ttl: ttl,
      });
      return 'success';
    }

    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.setRoleInPartnerAccount', traceId], {
      error: serializeError(response.data),
    });
    return 'failure';
  } catch (error) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.setRoleInPartnerAccount', traceId], {
      error: serializeError(error),
    });
    return 'failure';
  }
};
