import { AuthController } from '@controllers/auth-controller';
import { PARTNER_ROLES, REPORTING } from '@utils/controller-utils';
import { codeSplit } from 'bernie-core';

export class ReportingController extends AuthController {
  public pageId = 'Reporting';
  public path = '/reporting';
  public routeName = REPORTING;
  public bundles = [];
  public pageTitle = 'Reporting';
  public component = codeSplit(() => import(/* webpackChunkName: "reporting" */ '../../views/partner/reporting'));
  public exact = true;
  public isPartnerView = true;
  public accessRoles = [
    PARTNER_ROLES.ADVERTISER_ADMIN,
    PARTNER_ROLES.ADVERTISER_USER,
    PARTNER_ROLES.ADVERTISER_VIEWER,
    PARTNER_ROLES.ADMIN, //deprecated, to be removed
  ];
  public navIcon = 'data_trends';
}
