import { AuthController } from '@controllers/auth-controller';
import { PARTNER_ROLES, VIEW_AND_MANAGE } from '@utils/controller-utils';
import { codeSplit } from 'bernie-core';

export class ViewAndManageController extends AuthController {
  public pageId = VIEW_AND_MANAGE;
  public path = '/view-and-manage';
  public routeName = VIEW_AND_MANAGE;
  public bundles = [];
  public pageTitle = VIEW_AND_MANAGE;
  public accessRoles = [
    PARTNER_ROLES.ADVERTISER_ADMIN,
    PARTNER_ROLES.ADVERTISER_USER,
    PARTNER_ROLES.ADVERTISER_VIEWER,
    PARTNER_ROLES.ADMIN, //deprecated, to be removed
  ];
  public readOnlyAccessRoles = [PARTNER_ROLES.ADVERTISER_VIEWER, PARTNER_ROLES.ADVERTISER_USER];

  public component = codeSplit(
    () => import(/* webpackChunkName: "viewAndManage" */ '../../views/partner/view-and-manage'),
  );
  public exact = true;
  public navIcon = '';
}
