import { AuthController } from '@controllers/auth-controller';
import { CAMPAIGN_MANAGEMENT, PARTNER_ROLES } from '@utils/controller-utils';
import { codeSplit } from 'bernie-core';

export class CampaignManagementController extends AuthController {
  public pageId = CAMPAIGN_MANAGEMENT;
  public path = '/campaigns';
  public routeName = 'campaign-management';
  public bundles = [];
  public pageTitle = CAMPAIGN_MANAGEMENT;
  public isPartnerView = true;
  public navIcon = 'work';
  public accessRoles = [
    PARTNER_ROLES.ADVERTISER_ADMIN,
    PARTNER_ROLES.ADVERTISER_USER,
    PARTNER_ROLES.ADVERTISER_VIEWER,
    PARTNER_ROLES.ADMIN, //deprecated, to be removed
  ];
  public readOnlyAccessRoles = [PARTNER_ROLES.ADVERTISER_VIEWER];

  public component = codeSplit(
    () => import(/* webpackChunkName: "campaign-management" */ '../../views/partner/campaign-management'),
  );
  public exact = true;
}
