/* eslint-disable @typescript-eslint/no-unused-vars */
import { ERROR } from '@utils/controller-utils';
import { codeSplit, Controller, FetchOptions } from 'bernie-core';
import { HttpStatus, PageData } from 'bernie-http';

export class ErrorController implements Controller {
  public pageId = ERROR;
  public path = '/error';
  public routeName = ERROR;
  public bundles = [];
  public pageTitle = '';
  public component = codeSplit(() => import(/* webpackChunkName: "error" */ '../../views/error-page'));
  public isAuthenticated = false;
  public isPartnerProtected = false;
  public isPartnerView = true;
  public isAdminView = true;
  public exact = true;

  public fetch(options: FetchOptions) {
    // Deprecated data fetching paradigm shown here
    return Promise.resolve({ status: HttpStatus.OK });
  }

  public fetchPageData(): Promise<PageData> {
    return Promise.resolve({
      title: 'error',
      pageId: this.pageId,
      pageName: this.pageId,
    }) as Promise<PageData>;
  }
}
