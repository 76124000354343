import { IUser } from '@server/identity-utils';
import { getTraceId } from '@utils/trace-utils';
import { SerializedData } from 'bernie-core';
import { Request } from 'bernie-http';
import { Logger, NOOP_LOGGER, SystemEventLevel } from 'bernie-logger';
import { Store } from 'bernie-plugin-mobx';
import { action, makeObservable, observable } from 'mobx';
import { serializeError } from 'serialize-error';
import { ERROR } from 'src/constants';

export enum PARTNER_TYPE {
  FLIGHT_SPONSORED_LISTING = 'FLIGHT_SPONSORED_LISTING',
  LODGING_SPONSORED_LISTING = 'LODGING_SPONSORED_LISTING',
}

export class UserStore extends Store {
  @observable public name!: string;

  @observable public primaryEmail!: string;

  @observable public initials!: string;

  @observable public scopes?: string;

  @observable public partnerAccountId?: string;

  @observable public userId?: string;

  @observable public actingUserId?: string;

  @observable public sessionId?: string;

  @observable public partnerName: string;

  @observable public partnerRoles: string[] = [];

  @observable public sponsoredContentUser: boolean = false;

  @observable public adminContext: boolean = false;

  @observable public partnerType: PARTNER_TYPE;

  @observable public isOnboarded: boolean;

  constructor(config, logger: Logger = NOOP_LOGGER) {
    super({}, logger);
    makeObservable(this);
  }

  hydrate(data: SerializedData): void {
    Object.assign(this, data);
  }

  @action
  public setUserInfo = async ({ request, userInfo }: { request: Request; userInfo: IUser }): Promise<void> => {
    try {
      this.setUserContextData(userInfo);
    } catch (error) {
      const traceId = getTraceId(request);
      request.log([ERROR, 'advertiser-portal-pwa.error.setUserInfo', traceId], {
        level: SystemEventLevel.CRITICAL,
        error: serializeError(error),
      });
    }
  };

  @action
  public setPartnerType = async (partnerType: PARTNER_TYPE): Promise<void> => {
    this.partnerType = partnerType;
  };

  public toJSON(ignoreAdditionalKeys: string[] | string = []): SerializedData {
    const json = super.toJSON(ignoreAdditionalKeys);
    delete json.appConfig?.secret;
    delete json.appConfig?.credential;
    return json;
  }

  private setUserContextData(userInfo: IUser): void {
    this.scopes = userInfo.userJwt.scope;
    this.partnerAccountId = userInfo.userJwt.partner_account_id;
    this.userId = userInfo.userJwt.sub;
    this.actingUserId = userInfo.userJwt.actor_id;
    this.sessionId = userInfo.userJwt.jti;
    this.name = userInfo?.profile?.firstName + ' ' + userInfo?.profile?.lastName;
    this.primaryEmail = userInfo?.profile?.primaryEmail;
    this.initials = userInfo?.profile?.initials;
    this.partnerName = userInfo?.partner?.name;
    this.partnerRoles = userInfo?.roles;
    this.sponsoredContentUser = userInfo?.sponsoredContentUser;
    this.adminContext = userInfo?.adminContext;
    this.partnerType = userInfo?.partnerConfig?.partnerType;
    this.isOnboarded = userInfo?.partnerConfig?.isOnboarded ?? false;
  }
}
