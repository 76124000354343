import { SerializedData } from 'bernie-core';
import { Logger, NOOP_LOGGER } from 'bernie-logger';
import { Store } from 'bernie-plugin-mobx';
import { action, makeObservable, observable, runInAction } from 'mobx';

export class LayoutStore extends Store {
  @observable public loadingOverlay!: boolean;
  @observable public loadingOverlayHeader?: string;
  @observable public loadingOverlayBody?: string;
  @observable public loadingMainPageBody!: boolean;

  config;

  constructor(config, logger: Logger = NOOP_LOGGER) {
    super({}, logger);
    this.config = config;
    makeObservable(this);
  }

  @action
  public setLoadingOverlay(isLoading: boolean) {
    runInAction(() => {
      this.loadingOverlay = isLoading;
    });
  }

  @action
  public setLoadingOverlayHeader(header?: string) {
    this.loadingOverlayHeader = header;
  }

  @action
  public setLoadingOverlayBody(body?: string) {
    this.loadingOverlayBody = body;
  }

  @action
  public clearLoadingOverlay() {
    this.loadingOverlay = false;
    this.loadingOverlayHeader = null;
    this.loadingOverlayBody = null;
  }

  @action
  public setLoadingMainPageBody(loading: boolean) {
    this.loadingMainPageBody = loading;
  }

  hydrate(data: SerializedData): void {
    Object.assign(this, data);
  }

  public toJSON(ignoreAdditionalKeys: string[] | string = []): SerializedData {
    const json = super.toJSON(ignoreAdditionalKeys);
    delete json.appConfig?.secret;
    delete json.appConfig?.credential;
    return json;
  }
}
