import { AuthController } from '@controllers/auth-controller';
import { codeSplit } from 'bernie-core';

export class OnboardingController extends AuthController {
  public pageId = 'Onboarding';
  public path = '/onboarding';
  public routeName = 'onboarding';
  public bundles = [];
  public pageTitle = 'Onboarding';
  public accessRoles = [];
  public navIcon = '';

  public component = codeSplit(() => import(/* webpackChunkName: "onboarding" */ '../../views/partner/onboarding'));
  public exact = false;
  public isPartnerProtected = false;
  public isPartnerView = true;
  public isAdminView = true;
}
