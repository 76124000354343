import { BannerStore } from '@stores/banner-store';
import { ConfigStore } from '@stores/config-store';
import { LayoutStore } from '@stores/layout-store';
import { UserStore } from '@stores/user-store';
import { ClientLogger } from 'bernie-client';
import { ClientFactories } from 'bernie-plugin-mobx';

export const stores: ClientFactories = {
  config: () => new ConfigStore(ClientLogger.getLoggerWithIdentifier('stores.config')),
  layout: () => new LayoutStore(ClientLogger.getLoggerWithIdentifier('stores.layout')),
  user: () => new UserStore(ClientLogger.getLoggerWithIdentifier('stores.user')),
  banner: () => new BannerStore(ClientLogger.getLoggerWithIdentifier('stores.banner')),
};
