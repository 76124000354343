import { AuthController } from '@controllers/auth-controller';
import { PARTNER_ROLES, USERS } from '@utils/controller-utils';
import { codeSplit } from 'bernie-core';

export class UsersController extends AuthController {
  public pageId = USERS;
  public path = '/users';
  public routeName = USERS;
  public bundles = [];
  public pageTitle = USERS;
  public component = codeSplit(() => import(/* webpackChunkName: "users" */ '../../views/users'));
  public isAdminView = false;
  public isPartnerView = true;
  public exact = true;
  public accessRoles = [
    PARTNER_ROLES.ADVERTISER_ADMIN,
    PARTNER_ROLES.ADVERTISER_USER,
    PARTNER_ROLES.ADVERTISER_VIEWER,
    PARTNER_ROLES.ADMIN, //deprecated, to be removed
  ];
}
