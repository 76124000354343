import { isUserSponsoredContentUser, getRolesInPartnerAccount } from '@services/excalibur-service';
import { lookupUser } from '@services/identity-service';
import { PrincipalUserData, getJwtFromToken, getPrincipalToken } from '@services/identity-token-service';
import { IPartnerDetails, getPartnerAccount } from '@services/partner-service';
import { getConfigData } from '@services/platform-configuration-sync';
import { getUserProfile } from '@services/user-profile-service';
import { PARTNER_TYPE } from '@stores/user-store';
import { getTraceId } from '@utils/trace-utils';
import { getConfig } from 'bernie-config';
import { SystemEventLevel } from 'bernie-logger';
import { serializeError } from 'serialize-error';
import { ERROR } from 'src/constants';

export interface IPartnerSelect {
  id: string;
  name: string;
}

export interface IPartnerConfig {
  isOnboarded: boolean;
  partnerType: PARTNER_TYPE;
}

export interface IUser {
  userJwt: PrincipalUserData;
  profile: IUserProfile;
  partner: IPartnerDetails;
  roles: string[];
  sponsoredContentUser: boolean;
  adminContext: boolean;
  partnerConfig: IPartnerConfig;
}

interface IUserProfile {
  firstName: string;
  lastName: string;
  primaryEmail: string;
  initials: string;
}

export const getUserInfo: (request) => Promise<IUser> = async (request) => {
  try {
    const {
      app: { sponsoredContentPartnerAccountId },
    } = getConfig();
    const principalToken = await getPrincipalToken(request);

    const principalJwt = getJwtFromToken(principalToken.encodedJwt);
    let profileData = await getUserProfile(request, principalToken.encodedJwt, principalJwt.sub);
    // It's possible users wont show up if they haven't been migrated from
    // legacy FERRIS pool to newer BEX pool
    // Not sure when this will change

    /**
     * Handling a few scenarios here:
     *  1 - Possible user won't show up if they are in the legacy FERRIS pool, so doing a user lookup
     *  2 - Users created via UPL dont seem to have an email, so doing a user lookup and using that email
     */

    const email = profileData?.data?.contact_info?.primary_email?.email;

    if (!profileData || !profileData.data || !email) {
      const user = await lookupUser(request, principalToken.encodedJwt, principalJwt.sub);

      // Profile exists but no email
      if (profileData?.data && !email && user['identifier']['email']) {
        profileData.data.contact_info['primary_email'] = {
          email: user['identifier']['email'],
        };
      } else {
        // No profile returned

        // try FERRIS user pool
        const legacyId = user?.legacy_user_ids?.FERRIS?.egaid;
        if (legacyId) {
          profileData = await getUserProfile(request, principalToken.encodedJwt, legacyId);
        }

        if (!profileData) {
          // try EXPEDIA user pool
          const legacyId = user?.legacy_user_ids?.EXPEDIA?.egaid;
          if (legacyId) {
            profileData = await getUserProfile(request, principalToken.encodedJwt, legacyId);
          }
        }
      }

      // if still no profile data, return empty to not break the app
      if (!profileData) {
        profileData = {
          data: {
            basic_info: {
              first_name: '',
              last_name: '',
            },
            contact_info: {
              primary_email: {
                email: '',
              },
            },
          },
        };
      }
    }
    const partnerData = await getPartnerAccount(request, principalToken.encodedJwt, principalJwt.partner_account_id);
    const userRolesInPartnerAccount = await getRolesInPartnerAccount(
      request,
      principalToken,
      principalJwt.partner_account_id,
      true,
    );
    const isSponsoredContentUser = await isUserSponsoredContentUser(request, principalToken);

    const profile: IUserProfile = {
      firstName: profileData.data.basic_info.first_name,
      lastName: profileData.data.basic_info.last_name,
      primaryEmail: profileData.data.contact_info.primary_email?.email || '',
      initials: profileData.data.basic_info.first_name[0] + profileData.data.basic_info.last_name[0],
    };

    const partnerConfigData = await getConfigData(request, principalToken.encodedJwt, principalJwt.partner_account_id);

    // default to FLIGHT_SPONSORED_LISTING if no partner type is found
    const partnerConfig = {
      isOnboarded: partnerConfigData?.partner_account_configuration?.is_onboarded || false,
      partnerType:
        partnerConfigData?.partner_account_configuration?.enabled_ad_types[0] || PARTNER_TYPE.FLIGHT_SPONSORED_LISTING,
    };

    return {
      userJwt: principalJwt,
      profile,
      partner: partnerData,
      roles: userRolesInPartnerAccount.map((role) => role.id),
      sponsoredContentUser: isSponsoredContentUser,
      adminContext: partnerData.account_id === sponsoredContentPartnerAccountId,
      partnerConfig,
    };
  } catch (error) {
    const traceId = getTraceId(request);
    request?.log([ERROR, 'IDENTITY_UTILS', 'advertiser-portal-pwa.error.getUserInfo', traceId], {
      level: SystemEventLevel.CRITICAL,
      error: serializeError(error),
    });
    throw error;
  }
};
