import { LANDING } from '@utils/controller-utils';
import { codeSplit, Controller, FetchOptions, Result } from 'bernie-core';
import { PageData } from 'bernie-http';

export class LandingPageController implements Controller {
  public pageId = LANDING;
  public path = '/';
  public routeName = 'landing';
  public bundles = [];
  public pageTitle = LANDING;

  public isPartnerProtected: boolean = false;
  public isAuthenticated = false;
  public isPartnerView = true;
  public isAdminView = true;

  /**
   * Components may be referred to directly
   *   `public component = ExamplePage;`
   *
   * Or, the preferred option is to create a code split point that will generate a new bundle.
   *   Use webpack's magic comments `webpackChunkName: "INSERT_FRIENDLY_NAME_HERE"`
   *   This will give it a friendly name for the name of the file.
   */
  public component = codeSplit(() => import(/* webpackChunkName: "landing" */ '../../views/landing'));
  public exact = true;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public fetch(options: FetchOptions): Promise<Result> {
    return Promise.resolve({});
  }

  public fetchPageData(): Promise<PageData> {
    return Promise.resolve({ title: 'Expedia Group Ads Portal' }) as Promise<PageData>;
  }
}
