import { SelectAccountController } from '@controllers/common/select-account-controller';
import { controllers } from '@controllers/index';
import { ReportingController } from '@controllers/partner/reporting-controller';
import { getRolesInPartnerAccount } from '@server/apis/excalibur-service';
import { getConfigData } from '@server/apis/platform-configuration-sync';
import { PARTNER_TYPE } from '@stores/user-store';
import { getConfig } from 'bernie-config';
import { useLocation } from 'react-router-dom-v5-compat';

export const BILLING_CENTER = 'Billing Center';
export const CAMPAIGN_MANAGEMENT = 'Campaigns';
export const CAMPAIGN_WIZARD = 'Campaign Wizard';
export const ERROR = 'Error';
export const REPORTING = 'Reporting';
export const LANDING = 'Landing';
export const LOGOUT = 'Logout';
export const SELECT_ACCOUNT = 'Select Account';
export const USERS = 'Users';
export const VIEW_AND_MANAGE = 'View and Manage';
export const PAYMENT_PROFILE = 'Payment Profile';

export enum ADMIN_ROLES {
  INTERNAL_ADMIN = 'role/campaign-internal/admin',
  INTERNAL_COPY_REVIEWER = 'role/campaign-internal/content-reviewer',
  INTERNAL_TECH_SUPPORT = 'role/campaign-internal/tech-support',
  INTERNAL_ADMIN_VIEWER = 'role/campaign-internal/admin-viewer',
  INTERNAL_COLLECTIONS_MANAGER = 'role/campaign-internal/collections-manager',
  INTERNAL_REV_OPS = 'role/campaign-internal/revenue-ops',
  INTERNAL_SALES = 'role/campaign-internal/sales',
}

export const ADMIN_ROLE_LIST: string[] = Object.values(ADMIN_ROLES);

export enum PARTNER_ROLES {
  ADMIN = 'role/campaign-configuration/admin',
  ADVERTISER_ADMIN = 'role/campaign-configuration/advertiser-admin',
  ADVERTISER_USER = 'role/campaign-configuration/advertiser-user',
  ADVERTISER_VIEWER = 'role/campaign-configuration/advertiser-viewer',
}

export const PARTNER_ROLE_LIST: string[] = Object.values(PARTNER_ROLES);

export const getController = (routePath: string) => {
  const controllerObjects = controllers.map((Controller) => new Controller());
  return controllerObjects.find((controller) => controller['path'] === routePath);
};

export const hasControllerAccess = (userRoles: string[], accessRoles: string[]) => {
  if (userRoles !== undefined && accessRoles !== undefined && accessRoles.length > 0) {
    return userRoles.some((role) => accessRoles.includes(role));
  }
  return true;
};

export const useIsReadOnly = (userRoles: string[], pagePath?: string) => {
  const location = useLocation();

  const controller = getController(pagePath ? pagePath : location.pathname);
  if (controller && !controller['readOnlyAccessRoles']) {
    return false;
  }

  // filter out any of the roles we haven't explicitly defined
  return userRoles
    .filter((role) => PARTNER_ROLE_LIST.includes(role) || ADMIN_ROLE_LIST.includes(role))
    .every((role) => controller['readOnlyAccessRoles'].includes(role));
};

export const useHasAccess = (userRoles: string[], pagePath?: string) => {
  const location = useLocation();

  const controller = getController(pagePath ? pagePath : location.pathname);
  return userRoles.some((role) => controller['accessRoles'].includes(role));
};

export const getHomePage = async (request, token, partnerAccount) => {
  const {
    app: { sponsoredContentPartnerAccountId },
  } = getConfig();

  const partnerRoles = await getRolesInPartnerAccount(request, token, partnerAccount, true);

  // if no roles...they shouldn't be in this partner account
  if (!partnerRoles || partnerRoles.length === 0) {
    return new SelectAccountController();
  }

  // if FSL, return reporting controller
  const configData = await getConfigData(request, token, partnerAccount);
  if (partnerAccount != sponsoredContentPartnerAccountId) {
    if (configData?.partner_account_configuration?.enabled_ad_types?.includes(PARTNER_TYPE.FLIGHT_SPONSORED_LISTING)) {
      return new ReportingController();
    }
  }

  // Look up first controller they have access to
  const controllerObjects = controllers.map((Controller) => new Controller());
  const homeController = controllerObjects
    .filter((controller) => Object.prototype.hasOwnProperty.call(controller, 'navIcon'))
    .find(
      (controller) =>
        controller['accessRoles']?.some((role) => partnerRoles.map((partnerRole) => partnerRole.id).includes(role)),
    );
  return homeController;
};
