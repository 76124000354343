import { AdCopyReviewController } from '@controllers/admin/ad-copy-review-controller';
import { CampaignSearchController } from '@controllers/admin/campaign-search-controller';
import { AdminHelpAndSupportController } from '@controllers/admin/help-and-support-controller';
import { AdminInvoicesController } from '@controllers/admin/invoices-controller';
import { RejectAdCopyController } from '@controllers/admin/reject-ad-copy-controller';
import { ErrorController } from '@controllers/common/error-controller';
import { LandingPageController } from '@controllers/common/landing-page-controller';
import { LogoutController } from '@controllers/common/logout-controller';
import { OnboardingController } from '@controllers/common/onboarding-controller';
import { SelectAccountController } from '@controllers/common/select-account-controller';
import { CampaignManagementController } from '@controllers/partner/campaign-management-controller';
import { CampaignWizardController } from '@controllers/partner/campaign-wizard-controller';
import { HelpAndSupportController } from '@controllers/partner/help-and-support-controller';
import { InvoicesController } from '@controllers/partner/invoices-controller';
import { ReportingController } from '@controllers/partner/reporting-controller';
import { UsersController } from '@controllers/partner/users-controller';
import { ViewAndManageController } from '@controllers/partner/view-and-manage-controller';

// IMPORTANT
// Set controllers in the order they need to appear in sidebar

const partnerControllers = [
  CampaignManagementController,
  ReportingController,
  InvoicesController,
  HelpAndSupportController,
  UsersController,
  OnboardingController,
  CampaignWizardController,
  ViewAndManageController,
];
const adminControllers = [
  CampaignSearchController,
  AdCopyReviewController,
  RejectAdCopyController,
  AdminInvoicesController,
  AdminHelpAndSupportController,
];

export const controllers = [
  ErrorController,
  LandingPageController,
  LogoutController,
  SelectAccountController,
  ...partnerControllers,
  ...adminControllers,
];
